<template>
  <div>
    <h3>Members</h3>

    <v-row v-if="list" class="my-4">
      <v-col cols="12" sm="6" md="4" v-for="profile in list" :key="profile.id">
        <v-card outlined elevation="1" max-height="500">
          <ProfileView :profile="profile" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null
    }
  },
  components: {
    ProfileView: () => import('@/components/chef/profile/ProfileView')
  },
  data() {
    return {
      list: null
    }
  },
  computed: {
    viewLink() {
      return `https://${process.env.VUE_APP_DOMAIN}`
    }
  },
  mounted() {
    this.loadProducers()
  },
  methods: {
    async loadProducers() {
      this.list = await this.$store.dispatch('loadCommunityMembers', {
        id: this.id
      })
    }
  }
}
</script>
